import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Huawei, pushar för Google Play-alternativ: `}<a parentName="li" {...{
          "href": "https://www.techradar.com/news/huawei-pitches-its-alternative-to-google-play-store"
        }}>{`https://www.techradar.com/news/huawei-pitches-its-alternative-to-google-play-store`}</a></li>
      <li parentName="ul">{`Handshake Foundation donerar till KDE: `}<a parentName="li" {...{
          "href": "https://dot.kde.org/2020/01/21/kde-receives-generous-donation-handshake-foundation"
        }}>{`https://dot.kde.org/2020/01/21/kde-receives-generous-donation-handshake-foundation`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Rocket League slutar supportas på Mac/Linux: `}<a parentName="li" {...{
          "href": "https://www.rocketleague.com/news/ending-support-for-mac-and-linux/"
        }}>{`https://www.rocketleague.com/news/ending-support-for-mac-and-linux/`}</a><ul parentName="li">
          <li parentName="ul">{`Tim har lite svårt för Linux: `}<a parentName="li" {...{
              "href": "https://twitter.com/TimSweeneyEpic/status/964284402741149698"
            }}>{`https://twitter.com/TimSweeneyEpic/status/964284402741149698`}</a></li>
        </ul></li>
      <li parentName="ul">{`Digital Ocean säger upp folk: `}<a parentName="li" {...{
          "href": "https://techcrunch.com/2020/01/17/digitalocean-layoffs/"
        }}>{`https://techcrunch.com/2020/01/17/digitalocean-layoffs/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Mozillas Common Voice: `}<a parentName="li" {...{
          "href": "https://voice.mozilla.org/"
        }}>{`https://voice.mozilla.org/`}</a><ul parentName="li">
          <li parentName="ul">{`Dataset (även med Svenska!): `}<a parentName="li" {...{
              "href": "https://voice.mozilla.org/en/datasets"
            }}>{`https://voice.mozilla.org/en/datasets`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Battle`}</h2>
    <ul>
      <li parentName="ul">{`Steam- vs PS4-kontroller`}</li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Alex kan spåra sin PinePhone: den är troligen i Sverige!`}</li>
      <li parentName="ul">{`Seb har installerat elementaryOS och har funderingar`}<ul parentName="li">
          <li parentName="ul">{`nvidia-drivrutiner`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`apt-get`}</inlineCode>{` vs `}<inlineCode parentName="li">{`apt`}</inlineCode>{` vs `}<inlineCode parentName="li">{`snap`}</inlineCode>{` vs `}<inlineCode parentName="li">{`flatpak`}</inlineCode>{` vs `}<inlineCode parentName="li">{`cargo`}</inlineCode></li>
          <li parentName="ul">{`flaggor i pakethanterare, typ `}<inlineCode parentName="li">{`--classic`}</inlineCode>{`, `}<inlineCode parentName="li">{`--no-install-recommends`}</inlineCode>{`, `}<inlineCode parentName="li">{`-y`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`curl [repo]`}</inlineCode>{` - varför gör man detta?`}</li>
          <li parentName="ul">{`keychain`}</li>
        </ul></li>
    </ul>
    <h2>{`Feedback`}</h2>
    <ul>
      <li parentName="ul">{`@rgggn på twitter saknar oss i fediversumet och tipsar om mastodon.linuxkompis.se`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Cursed Intro - Dark Dramatic Epic Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      